import React, { Component } from 'react';
import { Bar, Radar } from 'react-chartjs-2';

// Sass
import './ResultsMedal.scss';

// Assets
import infoIcon from '../../assets/img/information.svg';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';

// Global fastar
const msf = [
  [2, 3, 0, 0, 3, 0, 0],
  [4, 4, 1, 0, 3, 1, 0],
  [5, 4, 2, 0, 4, 1, 1],
  [6, 5, 3, 0, 4, 1, 1],
];
const sf = [
  [5, 5, 2, 0, 4, 2, 1],
  [6, 5, 4, 0, 5, 3, 1],
  [8, 6, 5, 0, 5, 4, 2],
  [9, 7, 6, 0, 6, 4, 2],
];
const mf = [
  [10, 8, 7, 3, 7, 7, 4],
  [11, 8, 8, 6, 7, 8, 5],
  [12, 8, 9, 9, 8, 9, 6],
  [12, 8, 9, 12, 8, 9, 7],
];
const gf = [
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
];

class ResultsMedal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: parseInt(localStorage.getItem('fjoldiIProfi')),
      arrowupClass: 'hide',
      arrowdownClass: '',
      aldursbil: this.props.aldursbil,
      medal: [],
      medalArray: [],
      radarHover: false,
      barHover: false,
      enginnAAldursbili: false,
    };

    this.open = this.open.bind(this);
    this.calcMedalArray = this.calcMedalArray.bind(this);
    this.setRadarData = this.setRadarData.bind(this);
    this.setBarData = this.setBarData.bind(this);
    this.fjoldiAAldursbiliIflokk = this.fjoldiAAldursbiliIflokk.bind(this);
    this.radarMouseOver = this.radarMouseOver.bind(this);
    this.radarMouseLeave = this.radarMouseLeave.bind(this);
    this.barMouseOver = this.barMouseOver.bind(this);
    this.barMouseLeave = this.barMouseLeave.bind(this);
  }

  async componentDidMount() {
    const aldursbil = parseInt(this.state.aldursbil);
    await this.setState({
      msf: msf[aldursbil - 1],
      sf: sf[aldursbil - 1],
      mf: mf[aldursbil - 1],
      gf: gf[aldursbil - 1],
    });

    this.calcMedalArray();
    this.setRadarData();
    this.setBarData();
  }

  /*
  Notar aldursbil, fjölda og frammistöðu allra nemenda til að finna meðaltal
  í hverjum flokk fyrir sig
  */
 calcMedalArray() {
    const { aldursbil, fjoldi } = this.state;
    const rim = [];
    const samstofur = [];
    const samsett = [];
    const hljodgreining = [];
    const margraed = [];
    const ordhlutaeyding = [];
    const hljodtenging = [];

    let fjoldiAAldursbili = 0;
    for (let i = 0; i < fjoldi; i++) {
        const nemandi = localStorage.getItem(`nemandi${i + 1}`);
        if (nemandi) {
            const nemandiData = nemandi.split(',');
            if (nemandiData[7] == aldursbil) {  // Check for aldursbil match
                fjoldiAAldursbili++;
                rim.push(parseInt(nemandiData[8]));
                samstofur.push(parseInt(nemandiData[9]));
                samsett.push(parseInt(nemandiData[10]));
                hljodgreining.push(parseInt(nemandiData[11]));
                margraed.push(parseInt(nemandiData[12]));
                ordhlutaeyding.push(parseInt(nemandiData[13]));
                hljodtenging.push(parseInt(nemandiData[14]));
            }
        }
    }

    if (rim.length > 0 && fjoldiAAldursbili > 0) {
        const reducer = (acc, curr) => acc + curr;
        const medalArray = [
            rim.reduce(reducer) / fjoldiAAldursbili,
            samstofur.reduce(reducer) / fjoldiAAldursbili,
            samsett.reduce(reducer) / fjoldiAAldursbili,
            hljodgreining.reduce(reducer) / fjoldiAAldursbili,
            margraed.reduce(reducer) / fjoldiAAldursbili,
            ordhlutaeyding.reduce(reducer) / fjoldiAAldursbili,
            hljodtenging.reduce(reducer) / fjoldiAAldursbili,
        ];
        
        console.log("Calculated medalArray:", medalArray);  // Log the calculated array
        
        this.setState({ medalArray, fjoldiAAldursbili });
    } else {
        console.log("medalArray is empty or no students in the age range.");
        this.setState({ enginnAAldursbili: true });
    }
}

// Ensure radar data is set when medalArray changes
componentDidUpdate(prevProps, prevState) {
    if (prevState.medalArray !== this.state.medalArray && this.state.medalArray.length > 0) {
        console.log("Setting radar data with populated medalArray:", this.state.medalArray);
        this.setRadarData();  // Only call setRadarData if medalArray has been populated
    }
}

  setRadarData() {

      console.log("Setting radar data with medalArray:", this.state.medalArray);

    const data = {
      labels: [
        'Rím',
        'Samstöfur',
        'Samsett orð',
        'Hljóðgreining',
        'Margræð orð',
        'Orðhlutaeyðing',
        'Hljóðtenging',
      ],
      datasets: [
        {
          label: 'Meðaltal nemenda minna',
          data: this.state.medalArray,
          backgroundColor: 'rgb(243, 243, 23, 1)',
          borderWidth: 3,
          borderColor: 'rgb(243, 243, 23)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: false,
        },
        {
          label: 'Mjög slök færni',
          data: this.state.msf,
          backgroundColor: 'rgba(241, 74, 74, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(241, 74, 74)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
        },
        {
          label: 'Slök færni',
          data: this.state.sf,
          backgroundColor: 'rgba(163, 159, 159, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(163, 159, 159)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Meðalfærni',
          data: this.state.mf,
          backgroundColor: 'rgba(63, 151, 63, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(63, 151, 63)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Góð færni',
          data: this.state.gf,
          backgroundColor: 'rgba(97, 97, 221, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(97, 97, 221)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
      ],
    };
    const options = {
      title: {
        display: false,
      },
      scale: {
        angleLines: {
          display: false,
        },
        ticks: {
          suggestedMin: -3,
          suggestedMax: 16,
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontColor: '#000',
        },
      },
    };
    this.setState({ radarData: data, radarOptions: options });
  }

 setBarData() {
  const fjoldiIFlokk = [
    this.fjoldiAAldursbiliIflokk('msf'),
    this.fjoldiAAldursbiliIflokk('sf'),
    this.fjoldiAAldursbiliIflokk('mf'),
    this.fjoldiAAldursbiliIflokk('gf'),
  ];
  const sum =
    fjoldiIFlokk[0] + fjoldiIFlokk[1] + fjoldiIFlokk[2] + fjoldiIFlokk[3];
  const data = {
    labels: ['Viðmið', 'Mínir nemendur'],
    datasets: [
      {
        label: 'Mjög slök færni',
        data: [sum * 0.1, fjoldiIFlokk[0]],
        backgroundColor: 'rgba(241, 74, 74, 0.5)',
        borderWidth: 3,
        borderColor: 'rgb(241, 74, 74)',
        hoverBorderWidth: 5,
        hoverBorderColor: '#000',
        borderDash: [10, 5],
      },
      {
        label: 'Slök færni',
        data: [sum * 0.15, fjoldiIFlokk[1]],
        backgroundColor: 'rgba(163, 159, 159, 0.5)',
        borderWidth: 3,
        borderColor: 'rgb(163, 159, 159)',
        hoverBorderWidth: 5,
        hoverBorderColor: '#000',
      },
      {
        label: 'Meðalfærni',
        data: [sum * 0.5, fjoldiIFlokk[2]],
        backgroundColor: 'rgba(63, 151, 63, 0.5)',
        borderWidth: 3,
        borderColor: 'rgb(63, 151, 63)',
        hoverBorderWidth: 5,
        hoverBorderColor: '#000',
      },
      {
        label: 'Góð færni',
        data: [sum * 0.25, fjoldiIFlokk[3]],
        backgroundColor: 'rgba(97, 97, 221, 0.5)',
        borderWidth: 3,
        borderColor: 'rgb(97, 97, 221)',
        hoverBorderWidth: 5,
        hoverBorderColor: '#000',
      },
    ],
  };
  const options = {
    title: {
      display: true,
      fontSize: 20,
    },
    legend: {
      display: true,
      position: 'top',
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };
  this.setState({ barData: data, barOptions: options });
}


  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  // Tekur  : Streng sem samsvarar class fyrir lit
  //          Streng sem samsvarar aldursbili
  // Skilar : Tölu sem samsvarar fjölda nemenda á gefnu aldursbili sem hafa
  //          sömu færni og beðið var um.
  fjoldiAAldursbiliIflokk(faerni) {
    let num = 0;
    const { aldursbil, fjoldi } = this.state;

    for (let i = 1; i <= fjoldi; i++) {
      const nemandi = localStorage.getItem(`nemandi${i}`).split(',');
      if (aldursbil === nemandi[7] && nemandi[16] === faerni) {
        num += 1;
      }
    }
    return num;
  }

  radarMouseOver() {
    this.setState({ radarHover: true });
  }

  radarMouseLeave() {
    this.setState({ radarHover: false });
  }

  barMouseOver() {
    this.setState({ barHover: true });
  }

  barMouseLeave() {
    this.setState({ barHover: false });
  }

  render() {
    const {
      arrowupClass,
      arrowdownClass,
      aldursbil,
      radarData,
      radarOptions,
      barData,
      barOptions,
      radarHover,
      barHover,
      enginnAAldursbili,
    } = this.state;

    let radarClass;
    if (radarHover) {
      radarClass = 'hoverOver';
    }

    let barClass;
    if (barHover) {
      barClass = 'hoverOver';
    }

    if (enginnAAldursbili) {
      return null;
    } else if (radarData && barData) {
      return (
        <div className='resultsMedal col col-10 offset-col-1 col-md-12 offset-col-md-0'>
          <div>
            <div>
              <div className='myndaDiv'>
                <img
                  className={arrowupClass}
                  onClick={this.open}
                  src={arrowup}
                  alt='Ör upp'></img>
                <img
                  className={arrowdownClass}
                  onClick={this.open}
                  src={arrowdown}
                  alt='Ör niður'></img>
              </div>
            </div>
            <div className='textaDiv'>
              <p className='neutral'>
                Meðalframmistaða nemenda minna á aldursbili {aldursbil}
              </p>
            </div>
          </div>
          <div className={'chartdiv ' + arrowupClass}>
            <img
              onMouseOver={this.radarMouseOver}
              onMouseLeave={this.radarMouseLeave}
              src={infoIcon}
              alt='Upplýsingatákn'></img>
            <div className='hoverbox'>
              <Radar
                className='hoverbox__bottom'
                data={radarData}
                options={radarOptions}
              />
              <div className={'hoverbox__top ' + radarClass}>
                <p>
                  Litirnir rauður, grár, grænn og blár sýna svæðin sem afmarka
                  viðeigandi færniviðmið. Guli ferillinn sýnir meðaltalsgildi
                  nemenda sem voru prófaðir.
                </p>
                <p>
                  Hér má sjá hvort að nemendur í heild séu slakir í einhverjum
                  þætti prófsins og hvort að aðgerða sé þörf í kennslu innan
                  leikskólans.
                </p>
                <p>
                  Ef einn eða fleiri punktar eru farnir að nálgast miðju, það
                  er, komnir inn á rauða, gráa eða neðarlega á græna svæðið þá
                  ætti að huga að aðgerðum.
                </p>
              </div>
            </div>
          </div>
          <div className={'chartdiv ' + arrowupClass}>
            <img
              onMouseOver={this.barMouseOver}
              onMouseLeave={this.barMouseLeave}
              src={infoIcon}
              alt='Upplýsingatákn'></img>
            <div className='hoverbox'>
              <Bar
                className='hoverbox__bottom'
                data={barData}
                options={barOptions}
              />
              <div className={'hoverbox__top ' + barClass}>
                <p>
                  Vinstri stöpullinn sýnir hver stöðluð skipting ætti að vera á milli færniviðmiða. 
                  Gert er ráð fyrir að 10% barna hafi mjög
                  slaka færni, 15% hafa slaka færni, 50% barna hafa meðalfærni
                  og 25% ættu að ná góðri færni.
                </p>
                <p>
                  Hægri stöpullinn sýnir hversu margir af nemendum sem þreyttu
                  prófið á þessu aldursbili lentu í hverju færniviðmiði.
                </p>
                <p>
                  Ef hægri stöpullinn endurspeglar nokkurn veginn vinstri
                  stöpulinn eða ef græni og blái hlutinn taka meira pláss þá
                  ætti ekki að vera þörf á breyttum kennsluháttum. Ef rauða og
                  gráa svæðið í vinstri stöpul taka meira pláss en í
                  viðmiðunarstöpul, þá ætti leikskólinn að íhuga að breyta
                  áherslum til að ná betri málþroska.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ResultsMedal;
