import React, { Component } from 'react';
import { Radar, Line } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

// Sass
import './ResultsEinstaklings.scss';

// Components
import Button from '../button/Button';
import ResultsEinstaklingsTafla from '../resultsEinstaklingsTafla/ResultsEinstaklingsTafla.jsx';

// Assets
import { lita } from '../../assets/js/functions';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';
import infoIcon from '../../assets/img/information.svg';
import pdfIcon from '../../assets/img/pdf-icon.svg';
import getMMSLogo from './logo';

// Global fastar
const msf = [
  [2, 3, 0, 0, 3, 0, 0],
  [4, 4, 1, 0, 3, 1, 0],
  [5, 4, 2, 0, 4, 1, 1],
  [6, 5, 3, 0, 4, 1, 1],
];
const sf = [
  [5, 5, 2, 0, 4, 2, 1],
  [6, 5, 4, 0, 5, 3, 1],
  [8, 6, 5, 0, 5, 4, 2],
  [9, 7, 6, 0, 6, 4, 2],
];
const mf = [
  [10, 8, 7, 3, 7, 7, 4],
  [11, 8, 8, 6, 7, 8, 5],
  [12, 8, 9, 9, 8, 9, 6],
  [12, 8, 9, 12, 8, 9, 7],
];
const gf = [
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
];

class ResultsEinstaklings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: localStorage.getItem('fjoldiIProfi'),
      prevData: false,
      arrowupClass: 'hide',
      arrowdownClass: '',
      nemNum: this.props.nem,
      medalArray: [],
      radarHover: false,
      lineHover: false,
      open: false,
      fyrriGogn: false,
      seinniGogn: false,
    };

    this.open = this.open.bind(this);
    this.calcMedalArray = this.calcMedalArray.bind(this);
    this.dataFromPrevTest = this.dataFromPrevTest.bind(this);
    this.setRadarData = this.setRadarData.bind(this);
    this.setLineData = this.setLineData.bind(this);
    this.radarMouseOver = this.radarMouseOver.bind(this);
    this.radarMouseLeave = this.radarMouseLeave.bind(this);
    this.lineMouseOver = this.lineMouseOver.bind(this);
    this.lineMouseLeave = this.lineMouseLeave.bind(this);
    this.makePDF = this.makePDF.bind(this);
    this.resultsEinstaklingsTaflaCB =
      this.resultsEinstaklingsTaflaCB.bind(this);
  }

  async componentDidMount() {
    const nemNum = this.state.nemNum;
    const umsogn = localStorage.getItem(`umsogn${nemNum}`);
    const nemandi = localStorage.getItem(`nemandi${nemNum}`).split(',');
    const aldursbil = parseInt(nemandi[7]);
    const logo = getMMSLogo();

    await this.setState({ nemandi, aldursbil, logo, umsogn });

    // this.parseMedalArray();
    this.calcMedalArray();
    this.setRadarData();
    this.setLineData();
  }

 async componentDidUpdate(prevProps, prevState) {
  const nemNum = this.state.nemNum;

  // First condition: Handle the open state based on props
  if (prevProps.open !== this.props.open && this.props.open === nemNum) {
    if (!this.state.open) {
      await this.open(); // Await if open() is async
    }
    this.props.openedCB(nemNum);
  }

  // Second condition: Check if makePDF prop has changed and matches nemNum
  if (prevProps.makePDF !== this.props.makePDF && this.props.makePDF === nemNum) {
    setTimeout(() => {
      this.makePDF('all');
    }, 500);
  }

  // Third condition: Check if fyrriGogn or seinniGogn state has changed
  if (prevState.fyrriGogn !== this.state.fyrriGogn || prevState.seinniGogn !== this.state.seinniGogn) {
    this.setRadarData();
    this.setLineData();
  }

  // Fourth condition: Check if medalArray has changed and is populated
  if (prevState.medalArray !== this.state.medalArray && this.state.medalArray.length > 0) {
    console.log("Setting radar data with populated medalArray:", this.state.medalArray);
    this.setLineData(); // Assuming setRadarData should only be called when medalArray is populated
  }
}

  /*
  Notar aldursbil, fjölda og frammistöðu allra nemenda til að finna meðaltal
  í hverjum flokk fyrir sig
  */
  calcMedalArray() {
    const { aldursbil, fjoldi } = this.state;
    const rim = [];
    const samstofur = [];
    const samsett = [];
    const hljodgreining = [];
    const margraed = [];
    const ordhlutaeyding = [];
    const hljodtenging = [];
    for (let i = 0; i < fjoldi; i++) {
      const nemandi = localStorage.getItem(`nemandi${i + 1}`).split(',');
      if (parseInt(nemandi[7]) === aldursbil) {
        rim[i] = parseInt(nemandi[8]);
        samstofur[i] = parseInt(nemandi[9]);
        samsett[i] = parseInt(nemandi[10]);
        hljodgreining[i] = parseInt(nemandi[11]);
        margraed[i] = parseInt(nemandi[12]);
        ordhlutaeyding[i] = parseInt(nemandi[13]);
        hljodtenging[i] = parseInt(nemandi[14]);
      }
    }
    const reducer = (accumulator, curr) => accumulator + curr;
    if (rim[0]) {
      const medalArray = [
        rim.reduce(reducer) / fjoldi,
        samstofur.reduce(reducer) / fjoldi,
        samsett.reduce(reducer) / fjoldi,
        hljodgreining.reduce(reducer) / fjoldi,
        margraed.reduce(reducer) / fjoldi,
        ordhlutaeyding.reduce(reducer) / fjoldi,
        hljodtenging.reduce(reducer) / fjoldi,
      ];
      this.setState({ medalArray });
    } else {
      this.setState({ enginnAAldursbili: true });
    }
  }

  /*
  Fall sem tekur við gögnum frá ResultsEinstaklingsTafla sem tekur gögn frá FyrriSeinni.
  Setur í state gögn úr fyrri eða seinni prófun svo að hægt sé að bæta þeim inn á 
  radar og línurit.
  */
  resultsEinstaklingsTaflaCB(who, gogn) {
    if (who === 'fyrri') {
      this.setState({ fyrriGogn: gogn });
    } else if (who === 'seinni') {
      this.setState({ seinniGogn: gogn });
    }
  }

  // Tekur við gögnum frá componenti ResultsFyrriNidurstodur
  // Setur í gögn í state sem hefur áhrif á birtingu gagna fyrir einstakling
  async dataFromPrevTest(gogn) {
    await this.setState({
      prevData: true,
      prevRim: gogn[0],
      prevSamstofur: gogn[1],
      prevSamsett: gogn[2],
      prevHljodgreining: gogn[3],
      prevMargraed: gogn[4],
      prevOrdhlutaeyding: gogn[5],
      prevHljodtenging: gogn[6],
      prevTotal: gogn[7],
      prevAldursbil: gogn[8],
      prevLitur1: gogn[9],
      prevLitur2: gogn[10],
      prevLitur3: gogn[11],
      prevLitur4: gogn[12],
      prevLitur5: gogn[13],
      prevLitur6: gogn[14],
      prevLitur7: gogn[15],
      prevLiturTotal: gogn[16],
    });

    this.setRadarData();
    this.setLineData();
  }

  setRadarData() {
    const nemNum = this.state.nemNum;
    const nemandi = localStorage.getItem('nemandi' + nemNum).split(',');
    const aldursbil = nemandi[7];
    const datapoints = [
      nemandi[8],
      nemandi[9],
      nemandi[10],
      nemandi[11],
      nemandi[12],
      nemandi[13],
      nemandi[14],
    ];

    const data = {
      labels: [
        'Rím',
        'Samstöfur',
        'Samsett orð',
        'Hljóðgreining',
        'Margræð orð',
        'Orðhlutaeyðing',
        'Hljóðtenging',
      ],
      datasets: [
        {
          label: 'Frammistaða nemenda',
          data: datapoints,
          backgroundColor: 'rgb(243, 243, 23)',
          borderWidth: 3,
          borderColor: 'rgb(243, 243, 23)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: 'none',
        },
        {
          label: 'Mjög slök færni',
          data: msf[aldursbil - 1],
          backgroundColor: 'rgba(241, 74, 74, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(241, 74, 74)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
        },
        {
          label: 'Slök færni',
          data: sf[aldursbil - 1],
          backgroundColor: 'rgba(163, 159, 159, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(163, 159, 159)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Meðalfærni',
          data: mf[aldursbil - 1],
          backgroundColor: 'rgba(63, 151, 63, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(63, 151, 63)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Góð færni',
          data: gf[aldursbil - 1],
          backgroundColor: 'rgba(97, 97, 221, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(97, 97, 221)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
      ],
    };
    const options = {
      title: {
        display: false,
      },
      scale: {
        angleLines: {
          display: false,
        },
        ticks: {
          suggestedMin: -3,
          suggestedMax: 16,
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontColor: '#000',
        },
      },
    };

    if (this.state.fyrriGogn) {
      const fyrriGogn = this.state.fyrriGogn.slice(0, 7);
      data.datasets.unshift({
        label: 'Fyrri frammistaða nemenda',
        data: fyrriGogn,
        backgroundColor: 'rgb(240, 150, 0)',
        borderWidth: 1,
        borderColor: 'rgb(240, 150, 0)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: 'none',
      });
    }

    if (this.state.seinniGogn) {
      const seinniGogn = this.state.seinniGogn.slice(0, 7);
      data.datasets.unshift({
        label: 'Seinni frammistaða nemenda',
        data: seinniGogn,
        backgroundColor: 'rgb(240, 150, 0)',
        borderWidth: 1,
        borderColor: 'rgb(240, 150, 0)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: 'none',
      });
    }

    this.setState({ radarData: data, radarOptions: options });
  }

  // Ensure radar data is set when medalArray changes
// componentDidUpdate(prevProps, prevState) {
//     if (prevState.medalArray !== this.state.medalArray && this.state.medalArray.length > 0) {
//         console.log("Setting radar data with populated medalArray:", this.state.medalArray);
//         this.setLineData();  // Only call setRadarData if medalArray has been populated
//     }
// }
// 

  setLineData() {
    const nemandi = localStorage
      .getItem('nemandi' + this.state.nemNum)
      .split(',');
    const nemResults = [
      nemandi[8],
      nemandi[9],
      nemandi[10],
      nemandi[11],
      nemandi[12],
      nemandi[13],
      nemandi[14],
    ];
    const data = {
      labels: [
        'Rím',
        'Samstöfur',
        'Samsett orð',
        'Hljóðgreining',
        'Margræð orð',
        'Orðhlutaeyðing',
        'Hljóðtenging',
      ],
      datasets: [
        {
          label: 'Meðal frammistaða á aldursbili',
          data: this.state.medalArray,
          backgroundColor: 'rgba(29, 160, 204, 0.6)',
          borderWidth: 3,
          borderColor: 'rgba(29, 160, 204,0.6)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          borderDash: [10, 5],
        },
        {
          label: 'Frammistaða nemanda',
          data: nemResults,
          backgroundColor: 'rgba(243, 243, 23, 0.6)',
          borderWidth: 3,
          borderColor: 'rgba(243, 243, 23, 0.6)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
        },
      ],
    };
    const options = {
  scales: {
    y: {
      display: true,
      beginAtZero: true, // Sets the minimum value to 0.
      suggestedMax: 15, // Suggests a maximum value of 15.
    },
  },
};

    if (this.state.fyrriGogn) {
      const fyrriGogn = this.state.fyrriGogn.slice(0, 7);
      data.datasets.unshift({
        label: 'Fyrri frammistaða nemenda',
        data: fyrriGogn,
        backgroundColor: 'rgba(240, 150, 0, 0.6)',
        borderWidth: 3,
        borderColor: 'rgba(240, 150, 0, 0.6)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
      });
    }

    if (this.state.seinniGogn) {
      const seinniGogn = this.state.seinniGogn.slice(0, 7);
      data.datasets.push({
        label: 'Seinni frammistaða nemenda',
        data: seinniGogn,
        backgroundColor: 'rgba(240, 150, 0, 0.6)',
        borderWidth: 3,
        borderColor: 'rgba(240, 150, 0, 0.6)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
      });
    }

    this.setState({ lineData: data, lineOptions: options });
  }

  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  radarMouseOver() {
    this.setState({ radarHover: true });
  }

  radarMouseLeave() {
    this.setState({ radarHover: false });
  }

  lineMouseOver() {
    this.setState({ lineHover: true });
  }

  lineMouseLeave() {
    this.setState({ lineHover: false });
  }

  async makePDF(x) {
    const { nemNum, nemandi, logo, fjoldi, aldursbil } = this.state;
    let fjoldiAAldursbili = 0;

    for (let i = 1; i <= fjoldi; i++) {
      const nemandi = localStorage.getItem(`nemandi${i}`).split(',');
      console.log(aldursbil, nemandi[7]);

      if (aldursbil === parseInt(nemandi[7])) {
        fjoldiAAldursbili += 1;
      }
    }

    // Gera PDF skjalið
    const doc = new jsPDF();
    // Setja inn logo og fyrirsögn
    doc.setFontSize(12);
    doc.addImage(logo, 150, 20, 310 / 6.5, 112 / 6.5);
    doc.text(15, 50, 'Frammistöðuskýrsla úr HLJÓM-2 - ' + nemandi[1]);

    // Setja inn dagsetningu
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    doc.text(15, 35, today);

    // Setja inn heildarframmistöðu
    const frammistada = lita('heild', parseInt(nemandi[4]), this.state.heild);
    if (frammistada === 'msf') {
      doc.setDrawColor(241, 74, 74);
      doc.setFillColor(248, 164, 164);
      doc.rect(137, 44, 60, 10, 'FD');
      doc.text(152, 50, 'Mjög slök færni');
    } else if (frammistada === 'sf') {
      doc.setDrawColor(163, 159, 159);
      doc.setFillColor(209, 207, 207);
      doc.rect(137, 44, 60, 10, 'FD');
      doc.text(157, 50, 'Slök færni');
    } else if (frammistada === 'mf') {
      doc.setDrawColor(63, 151, 63);
      doc.setFillColor(159, 203, 159);
      doc.rect(137, 44, 60, 10, 'FD');
      doc.text(157, 50, 'Meðalfærni');
    } else if (frammistada === 'gf') {
      doc.setDrawColor(97, 97, 221);
      doc.setFillColor(176, 176, 238);
      doc.rect(137, 44, 60, 10, 'FD');
      doc.text(157, 50, 'Góð færni');
    }

    // Setja inn töflu
    const nemTafla = document.querySelector('.einstaklings__tafla' + nemNum);
    const preW = nemTafla.offsetWidth;
    const preML = nemTafla.marginLeft;
    const preMT = nemTafla.marginTop;
    nemTafla.style = 'width: 419px; margin-left: 0; margin-top: 0';
    const w = nemTafla.offsetWidth;
    const h = nemTafla.offsetHeight;

    // Setja inn umsögn
    const umsogn = this.state.umsogn;
    if (umsogn) {
      doc.setFontSize(10);
      const splitTitle = doc.splitTextToSize(umsogn, 80);
      doc.text(110, 68, splitTitle);
    }

    // Setja inn radar
    const nemRadar = document.querySelector('.radar' + nemNum);

    // A.m.k 4 á aldusbili til að gröf megi fara á pdf, persónuvernd.
    if (fjoldiAAldursbili < 4) {
      window.alert(
        'Línurit fylgir ekki með á PDF skjal ef færri en 4 nemendur eru skráðir á aldursbil.'
      );
      if (doc) {
        domtoimage.toPng(nemTafla).then((imgData) => {
          doc.addImage(imgData, 'PNG', 15, 60, w / 5, h / 5);
          nemTafla.style = `width: ${preW}; margin-left: ${preML}; margin-top: ${preMT};`;
        });

        domtoimage.toPng(nemRadar).then((imgData) => {
          doc.addImage(imgData, 'PNG', 35, 130, 829 / 6, 414 / 6);
          doc.save(nemandi[1] + '-Frammistada.pdf');
          if (x === 'all') {
            this.open();
            this.props.pdfCB(nemNum);
          }
        });
      }
    } else {
      // Setja inn línurit
      const nemLinurit = document.querySelector('.line' + nemNum);

      if (doc) {
        domtoimage.toPng(nemTafla).then((imgData) => {
          doc.addImage(imgData, 'PNG', 15, 60, w / 5, h / 5);
          nemTafla.style = `width: ${preW}; margin-left: ${preML}; margin-top: ${preMT};`;
        });

        domtoimage.toPng(nemRadar).then((imgData) => {
          doc.addImage(imgData, 'PNG', 35, 130, 829 / 6, 414 / 6);
        });

        domtoimage.toPng(nemLinurit).then((imgData) => {
          doc.addImage(imgData, 'PNG', 35, 210, 829 / 6, 414 / 6);
          doc.save(nemandi[1] + '-Frammistada.pdf');
          if (x === 'all') {
            this.open();
            this.props.pdfCB(nemNum);
          }
        });
      }
    }
  }

  render() {
    const {
      arrowupClass,
      arrowdownClass,
      nemNum,
      nemandi,
      radarData,
      radarOptions,
      radarHover,
      lineData,
      lineOptions,
      lineHover,
      umsogn,
    } = this.state;

    let nafn;
    let litur;
    if (nemandi) {
      nafn = nemandi[1];
      litur = lita('heild', parseInt(nemandi[7]), nemandi[15]);
    }

    let radarClass;
    if (radarHover) {
      radarClass = 'hoverOver';
    }

    let lineClass;
    if (lineHover) {
      lineClass = 'hoverOver';
    }

    if (radarData && lineData) {
      return (
        <div className='resultsEinstaklings'>
          <div>
            <div>
              <div className='myndaDiv'>
                <img
                  className={arrowupClass}
                  onClick={this.open}
                  src={arrowup}
                  alt='Ör upp'></img>
                <img
                  className={arrowdownClass}
                  onClick={this.open}
                  src={arrowdown}
                  alt='Ör niður'></img>
              </div>
            </div>
            <div className='textaDiv'>
              <p className={litur}>{nafn}</p>
            </div>
          </div>
          <div className={`content ${arrowupClass}`}>
            <div className='umsognNem'>
              <div className='downloadNem'>
                <img
                  className='downloadNem__pdf'
                  src={pdfIcon}
                  onClick={() => {
                    this.makePDF('justOne');
                  }}
                  alt='PDF icon'
                />
                <Button
                  onClick={() => {
                    this.makePDF('justOne');
                  }}
                  children='Sækja upplýsingar fyrir nemanda'
                />
              </div>
            </div>
            <p className='umsogn'>{umsogn}</p>
            <ResultsEinstaklingsTafla
              nemNum={nemNum}
              resultsEinstaklingsTaflaCB={this.resultsEinstaklingsTaflaCB}
            />
            <div className='chartdiv'>
              <img
                onMouseOver={this.radarMouseOver}
                onMouseLeave={this.radarMouseLeave}
                src={infoIcon}
                alt='Upplýsingatákn'></img>
              <div className='hoverbox'>
                <div className={'hoverbox__bottom radar' + nemNum}>
                  <Radar data={radarData} options={radarOptions} />
                </div>
                <div className={'hoverbox__top ' + radarClass}>
                  <p>
                    Litirnir rauður, grár, grænn og blár sýna svæðin sem afmarka
                    viðeigandi færniviðmið. Guli ferillinn sýnir meðaltals gildi
                    nemenda sem voru prófaðir.
                  </p>
                  <p>
                    Hér má sjá hvort að nemendur í heild séu slakir í einhverjum
                    þætti prófsins og hvort að aðgerða sé þörf í kennslu innan
                    leikskólans.
                  </p>
                  <p>
                    Ef einn eða fleiri punktar eru farnir að nálgast miðju, það
                    er, komnir inn á rauða, gráa eða neðarlega á græna svæðið þá
                    ætti að huga að aðgerðum.
                  </p>
                </div>
              </div>
            </div>
            <div className='chartdiv'>
              <img
                onMouseOver={this.lineMouseOver}
                onMouseLeave={this.lineMouseLeave}
                src={infoIcon}
                alt='Upplýsingatákn'></img>
              <div className='hoverbox'>
                <div className={'hoverbox__bottom line' + nemNum}>
                  <Line data={lineData} options={lineOptions} />
                </div>
                <div className={'hoverbox__top ' + lineClass}>
                  <p>
                    Þetta línurit ber saman frammistöðu nemanda við
                    meðalframmistöðu samnemenda sem eru á sama aldursbili.
                  </p>
                  <p>
                    Ef það sést í gulann lit þá hefur nemandi staðið sig betur
                    en samnemendur sínir í viðeigandi hluta prófsins. Ef það
                    sést í blátt þá stóð nemandinn sig verr í viðeigandi flokk
                    en samnemendur sínir.
                  </p>
                  <p>
                    Hér má einnig sjá að ef einn eða fleiri bláirpunktar dýfa
                    sér neðar en hinir, þá mætti auka áherslur á viðkomandi þátt
                    eða þætti.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ResultsEinstaklings;
